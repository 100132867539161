/* eslint-disable @typescript-eslint/no-explicit-any */
import AppContext from "dashboard/contexts/app-context";
import { capitalize } from "dashboard/utils";
import { Plus, SelectionPlus } from "phosphor-react";
import React, { useContext, useMemo, useState } from "react";
import { TableActionLink, ColumnConfig, TableV2 } from "ui/table-v2/Table";
import CustomFieldModal from "./CustomFieldModal";
import { TeamMemberGroup } from "backend/types";
import { ValueFormatterParams } from "ag-grid-community";
import pluralize from "pluralize";
import { TEAM_MEMBER_GROUP_LABEL_OVERRIDES } from "dashboard/pages/team-members/teamSettings/OnboardingItemModal";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import OnboardingItemModal, {
  OnboardingItemFormParams,
} from "dashboard/pages/team-members/teamSettings/OnboardingItemModal";
import { OnboardingItemTableRow } from "dashboard/pages/team-members/teamSettings/OnboardingItemsTable";
import { Notifier } from "ui";
import styles from "dashboard/pages/team-members/teamSettings/OnboardingItemsTable.module.css";
import { CustomField, MiterAPI } from "dashboard/miter";

type CustomFieldsTableProps = {
  parentType: CustomField["parent_type"];
  isOnboardingCustomFieldsTable?: boolean;
};

export type CustomFieldTableRow = CustomField & {
  onboarding_config?: {
    default_due_days_from_start?: number;
    default_team_member_checklists?: TeamMemberGroup[] | null;
    default_assignee?: TeamMemberGroup[] | null;
  };
};

const convertToOnboardingItemFormParams = (
  customField: CustomFieldTableRow | null
): OnboardingItemTableRow | undefined => {
  if (customField == null) {
    return undefined;
  }
  return {
    _id: customField._id,
    name: customField.name,
    default_due_days_from_start: customField.onboarding_config?.default_due_days_from_start,
    default_team_member_checklists: customField.onboarding_config?.default_team_member_checklists || [],
    default_assignee: customField.onboarding_config?.default_assignee || [],
  };
};

const CustomFieldTable: React.FC<CustomFieldsTableProps> = (props: CustomFieldsTableProps) => {
  const { parentType, isOnboardingCustomFieldsTable: isOnboardingCustomFieldsTable } = props;
  const { customFields, getCustomFields } = useContext(AppContext);
  const { groupLabeler } = useTeamMemberGroupLabeler(TEAM_MEMBER_GROUP_LABEL_OVERRIDES);

  const [adding, setAdding] = useState<boolean>(false);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldTableRow | null>(null);

  const handleRowClick = (customField) => {
    handleEdit(customField);
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleEdit = (customField) => {
    setSelectedCustomField(customField);
  };

  const handleModalClose = () => {
    setAdding(false);
    setSelectedCustomField(null);
  };

  const handleModalSubmit = () => {
    getCustomFields();
    setAdding(false);
  };

  const handleRemoveCustomFields = async (selectedRows: OnboardingItemTableRow[]) => {
    try {
      const customFieldsToUpdate = selectedRows.map((row) => ({
        _id: row._id,
        onboarding_config: null,
      }));
      const updatePromises = customFieldsToUpdate.map((cf) => MiterAPI.custom_fields.update(cf._id, cf));
      const results = await Promise.all(updatePromises);
      if (results.some((res) => res.error)) {
        throw new Error("One or more  custom field updates failed");
      }
      await getCustomFields();
      Notifier.success("Custom fields removed from defaults successfully.");
    } catch (error) {
      Notifier.error("Failed to remove custom fields from defaults.");
    }
  };

  const handleUpdateCustomField = async (row: OnboardingItemFormParams) => {
    try {
      const customFieldId = row.objectOption.value;
      const customFieldToUpdate = {
        onboarding_config: {
          default_team_member_checklists:
            row.default_team_member_checklists?.map((group) => group.value) ?? null,
          default_assignee: row.default_assignee?.map((group) => group.value) ?? null,
          default_due_days_from_start: row.default_due_days_from_start ?? null,
        },
      };
      const res = await MiterAPI.custom_fields.update(customFieldId, customFieldToUpdate);
      if (res.error) throw new Error(res.error);
      await getCustomFields();
      Notifier.success("Custom field updated successfully.");
    } catch (error) {
      Notifier.error("Failed to update custom field.");
    }
  };

  const tableData = useMemo(() => {
    return customFields
      .filter(
        (field) =>
          // Filter custom fields based on two criteria:
          // 1. The field's parent_type must match the specified parentType
          // 2. If isOnboardingCustomFieldsTable is true, only include fields with an onboarding_config
          //    Otherwise, include all fields that match the parent_type
          field.parent_type === parentType &&
          (!isOnboardingCustomFieldsTable || field.onboarding_config != null)
      )
      .map((field) => ({
        ...field,
        dataType: capitalize(field.type),
        team_member_access: field.team_member_access ? capitalize(field.team_member_access) : undefined,
      })) as CustomFieldTableRow[];
  }, [customFields]);

  const customFieldOptions = useMemo(() => {
    const existingFieldIds = new Set(tableData.map((field) => field._id));
    return customFields
      .filter((field) => field.parent_type === parentType && !existingFieldIds.has(field._id))
      .map((field) => ({
        label: field.name,
        value: field._id,
      }));
  }, [customFields, tableData, parentType]);

  const addButtonLabel = isOnboardingCustomFieldsTable ? "Add custom field" : "Add field";
  const addButtonIcon = isOnboardingCustomFieldsTable ? (
    <SelectionPlus weight="bold" style={{ marginRight: 3, marginBottom: -1 }} />
  ) : (
    <Plus weight="bold" style={{ marginRight: 3 }} />
  );

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: addButtonLabel,
      className: "button-2 no-margin",
      action: handleAdd,
      important: true,
      icon: addButtonIcon,
    },
  ];

  const columns = useMemo(() => {
    const baseColumns: ColumnConfig<CustomFieldTableRow>[] = [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Type",
        field: "type",
        dataType: "string",
        displayType: "badge",
        colors: {
          checkbox: "light-blue",
          select: "light-yellow",
          text: "light-green",
          paragraph: "light-purple",
          number: "light-red",
        },
      },
    ];

    if (parentType !== "job" && !isOnboardingCustomFieldsTable) {
      baseColumns.push({
        headerName: "Team member access",
        field: "team_member_access",
        dataType: "string" as const,
      });
    }

    if (isOnboardingCustomFieldsTable) {
      baseColumns.push(
        {
          field: "onboarding_config.default_due_days_from_start",
          headerName: "Due (days from start)",
          dataType: "number" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            if (params.value == null) {
              return "-";
            }

            const value = params.value as number;
            if (value > 0) {
              return `${value} ${pluralize("day", value)} after start`;
            } else {
              const absValue = Math.abs(value);
              return `${absValue} ${pluralize("day", absValue)} before start`;
            }
          },
        },
        {
          field: "onboarding_config.default_team_member_checklists",
          headerName: "Relevant team groups",
          dataType: "string" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            return (
              params.data?.onboarding_config?.default_team_member_checklists
                ?.map((group) => groupLabeler(group))
                .join(", ") || "-"
            );
          },
        },
        {
          field: "onboarding_config.default_assignee",
          headerName: "Default assignee",
          dataType: "string" as const,
          valueFormatter: (params: ValueFormatterParams<CustomFieldTableRow>): string => {
            return (
              params.data?.onboarding_config?.default_assignee
                ?.map((group) => groupLabeler(group))
                .join(", ") || "-"
            );
          },
        }
      );
    }

    baseColumns.push({
      headerName: "ID",
      field: "friendly_id",
      dataType: "string",
      hide: isOnboardingCustomFieldsTable,
    });

    return baseColumns;
  }, [parentType, groupLabeler]);

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={`${parentType}-custom-fields-table`}
        resource="custom fields"
        data={tableData}
        columns={columns}
        staticActions={staticActions}
        onClick={handleRowClick}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
        hideSearch={isOnboardingCustomFieldsTable}
        hidePrinter={isOnboardingCustomFieldsTable}
        hideExporter={isOnboardingCustomFieldsTable}
        containerClassName={isOnboardingCustomFieldsTable ? styles.customTable : undefined}
      />
    );
  };

  return (
    <div className="customField-table-wrapper">
      <div>
        {adding && !isOnboardingCustomFieldsTable && (
          <CustomFieldModal parentType={parentType} onHide={handleModalClose} onFinish={handleModalSubmit} />
        )}
        {selectedCustomField && !isOnboardingCustomFieldsTable && (
          <CustomFieldModal
            onHide={handleModalClose}
            onFinish={handleModalSubmit}
            customFieldID={selectedCustomField._id}
            parentType={parentType}
          />
        )}
        {(adding || selectedCustomField) && isOnboardingCustomFieldsTable && (
          <OnboardingItemModal
            selectedObject={convertToOnboardingItemFormParams(selectedCustomField)}
            onHide={handleModalClose}
            handleRemove={handleRemoveCustomFields}
            handleUpdate={handleUpdateCustomField}
            objectOptions={customFieldOptions}
            resourceName={"custom field"}
            includeDefaultAssignee={true}
          />
        )}
        {renderTable()}
      </div>
    </div>
  );
};

export default CustomFieldTable;
