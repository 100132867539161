import React, { FC, useState, useMemo, useEffect } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import {
  TeamMemberGroupSelectValue,
  TeamMemberOptionGroup,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { isMiterRep, notNullish } from "miter-utils";
import { OnboardingItemTableRow } from "./OnboardingItemsTable";
import styles from "./OnboardingItemModal.module.css";
import { useUser } from "dashboard/hooks/atom-hooks";
import { DueDateForm, DueDateFormProps, DueType } from "dashboard/components/team-members/DueDateForm";
import { TeamMemberGroupType } from "dashboard/miter";
import pluralize from "pluralize";

export type OnboardingItemFormParams = {
  objectOption: Option<string>;
  default_team_member_checklists: Option<TeamMemberGroupSelectValue>[] | null;
  default_assignee: Option<TeamMemberGroupSelectValue>[] | null;
  default_due_days_from_start: number | null;
};

export const TEAM_MEMBER_GROUP_LABEL_OVERRIDES = {
  direct_manager: "New Hire's Direct Manager",
  self: "New Hire",
} as const;

export const DEFAULT_ASSIGNEE_EXCLUDED_GROUPS: TeamMemberGroupType[] = [
  "pay_type",
  "employment_type",
  "all_team_members",
  "job_supervisor",
  "job_superintendent",
  "direct_managers",
  "crew_lead",
];

export const DEFAULT_ASSIGNEE_EXCLUDED_TYPES: TeamMemberGroupType[] = ["self"];

const buildDefaultValues = (
  teamMemberGroupOptions: TeamMemberOptionGroup[],
  object?: OnboardingItemTableRow
): OnboardingItemFormParams | undefined => {
  if (!object) {
    return;
  }

  const teamMemberOptions = teamMemberGroupOptions.flatMap((group) => group.options);

  const defaultTeamMemberChecklists = (object.default_team_member_checklists || [])
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  const defaultAssignee = (object.default_assignee || [])
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  const returnData = {
    objectOption: { label: object.name ?? "", value: object._id },
    default_team_member_checklists: defaultTeamMemberChecklists || [],
    default_assignee: defaultAssignee || [],
    default_due_days_from_start: object.default_due_days_from_start ?? null,
  };

  return returnData;
};

type Props = {
  selectedObject?: OnboardingItemTableRow;
  objectOptions: Option<string>[];
  onHide: () => void;
  handleRemove: (selectedRows: OnboardingItemTableRow[]) => Promise<void>;
  handleUpdate: (row: OnboardingItemFormParams) => Promise<void>;
  resourceName?: string;
  includeDefaultAssignee?: boolean;
};

const OnboardingItemModal: FC<Props> = ({
  selectedObject,
  objectOptions,
  onHide,
  handleRemove,
  handleUpdate,
  resourceName,
  includeDefaultAssignee,
}) => {
  const activeUser = useUser();

  const [selectedTmGroupOptionsDefaultGroups, setSelectedTmGroupOptionsDefaultGroups] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >([]);

  const [selectedTmGroupOptionsDefaultAssignees, setSelectedTmGroupOptionsDefaultAssignees] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >([]);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  });

  const form = useForm<OnboardingItemFormParams>({
    defaultValues: buildDefaultValues(teamMemberGroupOptions, selectedObject),
  });

  const teamMemberGroupOptionsDefaultGroups = useTeamMemberGroupOptions({
    selectedGroupOptions: selectedTmGroupOptionsDefaultGroups,
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  });

  const teamMemberGroupOptionsDefaultAssignees = useTeamMemberGroupOptions({
    selectedGroupOptions: selectedTmGroupOptionsDefaultAssignees,
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
    excludedGroups: DEFAULT_ASSIGNEE_EXCLUDED_GROUPS,
    excludedTypes: DEFAULT_ASSIGNEE_EXCLUDED_TYPES,
  });

  const { control, handleSubmit, errors, watch, setError, setValue, register } = form;
  const formData = form.getValues();

  useEffect(() => {
    register("default_due_days_from_start");
  }, [register]);

  const default_due_days_from_start = watch("default_due_days_from_start");

  const { days, dueType } = useMemo(() => {
    if (default_due_days_from_start == null) {
      return { days: null, dueType: null };
    }
    const days = Math.abs(default_due_days_from_start);
    const dueType =
      default_due_days_from_start && default_due_days_from_start >= 0
        ? "days_from_start"
        : "days_before_start";
    return { days, dueType };
  }, [default_due_days_from_start]);

  const handleDueDateChange: DueDateFormProps["onChange"] = (days, dueType) => {
    const newDueDaysFromStart = dueType === "days_from_start" ? days : days * -1;
    setValue("default_due_days_from_start", newDueDaysFromStart);
  };

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTmGroupOptionsDefaultGroups(formData.default_team_member_checklists || []);
  }, [JSON.stringify(formData.default_team_member_checklists)]);

  useEffect(() => {
    setSelectedTmGroupOptionsDefaultAssignees(formData.default_assignee || []);
  }, [JSON.stringify(formData.default_assignee)]);

  const updateOnboardingItem = async (data: OnboardingItemFormParams) => {
    setLoading(true);
    if (!data.objectOption?.value) {
      setError("objectOption", { message: "You must make a selection." });
      setLoading(false);
      return;
    }

    if (data.default_due_days_from_start != null && isNaN(data.default_due_days_from_start)) {
      Notifier.error("Due date days field must be a number.");
      setLoading(false);
      return;
    }

    if (!data.default_team_member_checklists?.length && !data.default_assignee?.length) {
      const errorMessage = includeDefaultAssignee
        ? "At least one default team member group or default assignee must be selected."
        : "At least one default team member group must be selected.";
      Notifier.error(errorMessage);
      setLoading(false);
      return;
    }
    // If we're changing the object when making this update, we need to remove the existing object first
    if (selectedObject && data.objectOption.value !== selectedObject._id) {
      await handleRemove([selectedObject]);
    }
    // We are never creating a new object, we are just updating the existing one
    await handleUpdate(data);
    setLoading(false);
    onHide();
  };

  const handleDelete = async () => {
    if (!selectedObject) return;

    setLoading(true);
    await handleRemove([selectedObject]);
    setLoading(false);
    onHide();
  };

  const renderForm = () => {
    return (
      <div className={`${styles.modalBody}`}>
        <Formblock
          type="select"
          name="objectOption"
          label="Name*"
          className="modal"
          form={form}
          editing={true}
          errors={errors}
          options={objectOptions}
        />
        <DueDateForm
          className={styles.dueDateFormMargin}
          days={days}
          dueType={dueType as DueType}
          onChange={handleDueDateChange}
          errors={errors}
        />
        <Formblock
          type="multiselect"
          name="default_team_member_checklists"
          label="Default team member groups"
          labelInfo="Select the default team member groups."
          form={form}
          control={control}
          editing={true}
          className="modal"
          placeholder="Select team member groups"
          options={teamMemberGroupOptionsDefaultGroups}
          height="unset"
        />
        {includeDefaultAssignee && (
          <Formblock
            type="multiselect"
            name="default_assignee"
            label="Default assignee"
            labelInfo="Select a default assignee."
            form={form}
            control={control}
            editing={true}
            className="modal"
            placeholder="Select assignee groups"
            options={teamMemberGroupOptionsDefaultAssignees}
            height="unset"
          />
        )}
      </div>
    );
  };

  return (
    <ActionModal
      headerText={
        selectedObject
          ? `Edit ${pluralize.singular(resourceName || "")}`
          : `Add ${pluralize.singular(resourceName || "")}`
      }
      showSubmit={true}
      showCancel={true}
      showDelete={!!selectedObject}
      deleteDisabled={!selectedObject}
      onDelete={handleDelete}
      deleteText="Remove"
      cancelText="Close"
      onCancel={onHide}
      submitText="Save"
      onHide={onHide}
      onSubmit={handleSubmit(updateOnboardingItem)}
      loading={loading}
      wrapperClassName={"form"}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default OnboardingItemModal;
