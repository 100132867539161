import React, { useEffect, useMemo } from "react";
import { formatDate, getPaperCheckTotalForPayroll } from "dashboard/utils/utils";
import PaymentsTable from "../PaymentsTable";
import PayrollContext from "../payrollContext";
import PayrollTotals from "../PayrollTotals";

import InfoButton from "dashboard/components/information/information";
import { PayrollCheckWarnings } from "../PayrollCheckWarnings";
import { Toggler, usdString } from "ui";
import { NonDraftPayrollReports } from "../nonDraftPayroll/NonDraftPayrollReports";
import { useNavigate, useParams } from "react-router-dom";
import { PayrollBills } from "../PayrollBills";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

export const PreviewPayroll: React.FC = () => {
  const { payroll, getPayroll } = React.useContext(PayrollContext);
  const navigate = useNavigate();
  const { view } = useParams<{ view: string }>();
  const activeTab = view || "summary";
  const { cannot } = useMiterAbilities();

  // Helpers
  const checkPayroll = payroll?.check_payroll;
  const totals = checkPayroll?.totals;
  const paperCheckAmount = useMemo(() => getPaperCheckTotalForPayroll(payroll), [payroll]);
  const debitDate = formatDate(checkPayroll?.approval_deadline, undefined, true);
  const hasWarnings = !!payroll?.miter_payments.some((mp) => !!mp.check_item?.warnings?.length);

  const togglerItems = [
    { label: "Summary", path: "summary" },
    { label: "Payments", path: "payments" },
    { label: "Bills", path: "bills", hide: cannot("bill_pay:bills:read") },
    { label: "Reports", path: "reports" },
    ...(hasWarnings ? [{ label: "Warnings", path: "warnings" }] : []),
  ];

  const handleToggle = (path: string) => {
    navigate("/payrolls/" + payroll!._id + "/" + path, { replace: true });
  };

  // Effects
  useEffect(() => {
    if (!totals) {
      getPayroll({ preview: "true" });
    }
  }, [payroll]);

  useEffect(() => {
    handleToggle("summary");
  }, []);

  if (!totals) return null;

  return (
    <div style={{ marginTop: -40 }}>
      <Toggler toggle={handleToggle} active={activeTab} config={togglerItems} />
      <div className="vertical-spacer"></div>
      {activeTab === "summary" && (
        <>
          <div className="flex draft-payroll-instructions-wrapper">
            <div className="draft-payroll-instructions">
              <div className="total-box-wrapper">
                <div className="total-box">
                  <div className="total-box-label">
                    <div>Total liability</div>
                    <InfoButton text="The total amount owed to employees, contractors, tax agencies, and benefits providers." />
                  </div>
                  <div className="total-box-value">{usdString(totals.liability)}</div>
                </div>
                <div className="total-box">
                  <div className="total-box-label">Debit amount</div>
                  <div className="total-box-value">{usdString(totals.cash_requirement)}</div>
                </div>
                <div className="total-box">
                  <div className="total-box-label">Debit date</div>
                  <div className="total-box-value">{debitDate}</div>
                </div>
                <div className="total-box">
                  <div className="total-box-label">Paper checks</div>
                  <div className="total-box-value">{usdString(paperCheckAmount)}</div>
                </div>
              </div>
            </div>
          </div>
          <PayrollTotals />
        </>
      )}
      {activeTab === "bills" && <PayrollBills />}
      {activeTab === "warnings" && <PayrollCheckWarnings />}
      {activeTab === "payments" && (
        <div className="payroll-summary-block">
          <PaymentsTable editing={false} />
        </div>
      )}

      {activeTab === "reports" && (
        <NonDraftPayrollReports
          reportSlugs={[
            "payroll_register",
            "deductions_contributions",
            "ledger_entry",
            "401k_report",
            "fringe_report",
            "earnings_summary",
            "health_cues",
          ]}
        />
      )}

      <div className="vertical-spacer"></div>
    </div>
  );
};
