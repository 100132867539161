import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { PersonalInfoOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { Formblock } from "ui";

export const PersonalInfoTaskContent: React.FC<ChecklistTaskContentProps<PersonalInfoOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const handleFieldChange = (field: string, value: boolean) => {
    if (!setTask) return;

    setTask({
      ...task,
      data: {
        ...task.data,
        fields: {
          ...DEFAULT_PERSONAL_INFO_FIELDS,
          ...task.data?.fields,
          [field]: value,
        },
      },
    });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <div className={styles["checklist-task-content"]}>
        {PERSONAL_INFO_FIELD_OPTIONS.map(({ value: field, label }) => (
          <Formblock
            key={"personal-info-" + field}
            name={label}
            type="checkbox"
            text={label}
            defaultValue={task.data?.fields?.[field]}
            editing={true}
            className="modal"
            style={{ marginBottom: 0, marginTop: 0 }}
            onChange={(e) => handleFieldChange(field, e.target.checked)}
          />
        ))}
        <DueDateForm task={task} setTask={setTask} />
      </div>
    </div>
  );
};

const PERSONAL_INFO_FIELD_OPTIONS = [
  { value: "ssn", label: "SSN" },
  { value: "dob", label: "Date of Birth" },
  { value: "address", label: "Address" },
  { value: "language", label: "Language" },
  { value: "emergency_contacts", label: "Emergency Contacts" },
  { value: "profile_picture", label: "Profile Picture" },
];

const DEFAULT_PERSONAL_INFO_FIELDS = {
  ssn: true,
  dob: true,
  address: true,
  language: true,
  emergency_contacts: true,
  profile_picture: true,
};
