import React from "react";
import { Label, Formblock } from "ui";
import { Option } from "packages/ui/form/Input";
import { DeepMap, FieldError } from "react-hook-form";

export type DueType = "days_from_start" | "days_before_start";

export type DueDateFormProps = {
  className?: string;
  days?: number | null;
  dueType: DueType | null;
  onChange: (days: number, dueType: DueType | null) => void;
  errors?: DeepMap<$TSFixMe, FieldError>;
};

export const DueDateForm: React.FC<DueDateFormProps> = ({
  days,
  dueType,
  onChange,
  errors,
  className = "",
}) => {
  const handleDaysChange = (value: string) => {
    onChange(Number(value), dueType);
  };

  const handleDueTypeChange = (option: Option<DueType>) => {
    if (days != null) {
      onChange(days, option.value as DueType);
    }
  };

  return (
    <div className={className}>
      <Label label="Due" style={{ marginBottom: 7, fontSize: "0.9rem" }} />
      <div className="flex">
        <Formblock
          type="append"
          name="days"
          editing={true}
          defaultValue={days}
          className="modal"
          onChange={(e) => handleDaysChange(e.target.value)}
          style={{ width: 80, marginRight: 10 }}
          appendText="days"
          errors={errors}
        />
        <Formblock
          type="select"
          name="due_type"
          editing={true}
          defaultValue={dueType}
          options={DUE_TYPE_OPTIONS}
          className="modal"
          onChange={(option) => handleDueTypeChange(option as Option<DueType>)}
          style={{ width: 175 }}
          errors={errors}
        />
      </div>
    </div>
  );
};

export const DUE_TYPE_OPTIONS: Option<DueType>[] = [
  { value: "days_before_start", label: "Before start date" },
  { value: "days_from_start", label: "After start date" },
];
