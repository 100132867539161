import { ChecklistTaskContentProps, ChecklistTaskItem } from "dashboard/utils/checklist-utils";
import { Label, Formblock } from "ui";
import { useMemo } from "react";
import { Option } from "packages/ui/form/Input";
import { TeamMemberOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { DUE_TYPE_OPTIONS } from "dashboard/components/team-members/DueDateForm";

type DueType = "days_from_start" | "days_before_start";

export const DueDateForm = <T extends TeamMemberOnboardingTaskForm>({
  task,
  setTask,
}: ChecklistTaskContentProps<T>): JSX.Element => {
  const days = useMemo(() => Math.abs(task.data?.due_days_from_start || 0), [task]);
  const dueType = useMemo(() => buildDefaultDueType(task), [task]);

  /** Handler to update the task when due changes */
  const handleDueChange = (days: number, dueType?: DueType) => {
    const newDueDaysFromStart = dueType === "days_from_start" ? days : days * -1;
    setTask({ ...task, data: { ...task.data, due_days_from_start: newDueDaysFromStart } });
  };

  return (
    <div>
      <div className="vertical-spacer" />
      <Label label="Due" style={{ marginBottom: 7, fontSize: "0.9rem" }} />
      <div className="flex">
        <Formblock
          type="append"
          name="days"
          editing={true}
          defaultValue={days}
          className="modal"
          onChange={(e) => handleDueChange(Number(e.target.value), dueType?.value)}
          style={{ width: 80, marginRight: 10 }}
          appendText="days"
        />
        <Formblock
          type="select"
          name="due_type"
          editing={true}
          defaultValue={dueType?.value}
          options={DUE_TYPE_OPTIONS}
          className="modal"
          onChange={(option) => handleDueChange(days, option.value || null)}
          style={{ width: 175 }}
        />
      </div>
    </div>
  );
};

const buildDefaultDueType = (
  task: ChecklistTaskItem<TeamMemberOnboardingTaskForm>
): Option<DueType> | null => {
  if (!task.data) return null;

  if (task.data.due_days_from_start !== undefined) {
    return task.data.due_days_from_start > 0 ? DUE_TYPE_OPTIONS[1]! : DUE_TYPE_OPTIONS[0]!;
  }

  return null;
};
