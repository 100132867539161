import React, { useEffect, useState, useContext, useMemo } from "react";
import { reportList } from "../../reportList";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { ColDef, GridApi, ValueGetterParams } from "ag-grid-community";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { WorkersCompReportRow } from "backend/utils/reports/workersComp";
import { roundTo, toDollarFormat } from "miter-utils";
import { useActiveCompanyId, useJobNameFormatter, useLookupJob } from "dashboard/hooks/atom-hooks";

import * as vals from "dashboard/utils/validators";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";

const reportObject = reportList.find((report) => report.slug === "workers_comp");

const CreateWorkersCompReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const jobHierarchyTableColumns = useJobHierarchyTableColumns<WorkersCompReportRow>();

  const { control, clearErrors, setError, errors } = useForm();
  const navigate = useNavigate();
  const { payroll } = useContext(PayrollContext);

  const lookupJob = useLookupJob();
  const jobFormatter = useJobNameFormatter();

  // State
  const [data, setData] = useState<WorkersCompReportRow[]>([]);
  const [startDate, setStartDate] = useState(DateTime.now().minus({ months: 1 }));
  const [endDate, setEndDate] = useState(DateTime.now());
  const [gridApi, setGridApi] = useState<GridApi>();

  const getData = async () => {
    if (!gridApi) return;
    gridApi.showLoadingOverlay();
    try {
      const cleanedData = {
        start_date: payroll?.check_payroll.payday || startDate.toISODate(),
        end_date: payroll?.check_payroll.payday || endDate.toISODate(),
        company_id: activeCompanyId!,
        payrollId: payroll?._id,
      };
      const payload = {
        type: "workers_comp",
        params: cleanedData,
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const filteredData = response;
      setData(filteredData);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error getting your report data. We're looking into it!");
    }
    gridApi.hideOverlay();
  };

  const onStartDateChange = (dt) => {
    setStartDate(dt);

    if (dt.toISODate() > endDate.toISODate()) {
      setError("end_date", { message: "End date cannot be before start date." });
    } else {
      clearErrors("end_date");
    }
  };

  const onEndDateChange = (dt) => {
    setEndDate(dt);

    if (startDate.toISODate() > dt.toISODate()) {
      setError("end_date", { message: "End date cannot be before start date." });
    } else {
      clearErrors("end_date");
    }
  };

  const maxAllowableDate = DateTime.now().plus({ days: 10 });

  let fileName = "Miter Workers' Comp Report ";
  if (payroll) {
    fileName += payroll.check_payroll.payday;
  } else {
    fileName += startDate.toISODate() + " - " + endDate.toISODate();
  }

  useEffect(() => {
    getData();
  }, [!!gridApi, startDate, endDate]);

  const defaultExportParams = {
    allColumns: false,
    fileName: fileName,
  };

  const HeaderTag = payroll ? "h2" : "h1";

  const workersCompColumnDefs = useMemo(() => {
    const cols: ColDef<WorkersCompReportRow>[] = [
      {
        field: "team_member_name",
        filter: true,
        headerName: "Team member",
        rowGroup: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "team_member_friendly_id",
        filter: true,
        headerName: "Team member ID",
        rowGroup: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "team_member_title",
        filter: true,
        headerName: "Team member title",
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "wc_code",
        filter: true,
        headerName: "Workers comp code",
        rowGroup: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        field: "wc_code_label",
        filter: true,
        headerName: "Workers' comp label",
        rowGroup: true,
        initialHide: true,
        enableRowGroup: true,
      },
      {
        headerName: "Job",
        valueGetter: (params: ValueGetterParams) => {
          const jobName = jobFormatter(params.data?.job_id);
          return jobName || "-";
        },
        filter: true,
        enableRowGroup: true,
      },
      {
        field: "earning_state",
        headerName: "Earning state",
        filter: true,
        enableRowGroup: true,
        sortable: true,
      },
      {
        field: "is_ocip",
        filter: true,
        headerName: "OCIP",
        initialHide: true,
      },
      {
        headerName: "Customer",
        valueGetter: (params: ValueGetterParams) => {
          const job = lookupJob(params.data?.job_id);
          return job?.customer?.name || "-";
        },
        filter: true,
        enableRowGroup: true,
      },
      {
        field: "reg_hours",
        headerName: "REG hours",
        aggFunc: "sumValues",
      },
      {
        field: "ot_hours",
        headerName: "OT hours",
        aggFunc: "sumValues",
      },
      {
        field: "dot_hours",
        headerName: "DOT hours",
        aggFunc: "sumValues",
      },
      {
        field: "sick_hours",
        headerName: "Sick hours",
        aggFunc: "sumValues",
      },
      {
        field: "vacation_hours",
        headerName: "Vacation hours",
        aggFunc: "sumValues",
      },
      {
        field: "holiday_hours",
        headerName: "Holiday hours",
        aggFunc: "sumValues",
      },
      {
        field: "total_hours",
        headerName: "Total hours",
        aggFunc: "sumValues",
      },
      {
        field: "earnings",
        headerName: "Gross earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        headerName: "Hourly subject earnings",
        headerTooltip: "Hourly earnings excluding non-REG portion of OT and DOT",
        aggFunc: "sumValues",
        valueGetter: subjectWagesValueGetter,
        filterValueGetter: subjectWagesValueGetter,
        valueFormatter: toDollarFormat,
      },
      {
        field: "reg_earnings",
        headerName: "REG earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "ot_earnings",
        headerName: "OT earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "dot_earnings",
        headerName: "DOT earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "sick_earnings",
        headerName: "Sick earnings",
        aggFunc: "sumValues",
        hide: true,
        valueFormatter: toDollarFormat,
      },
      {
        field: "vacation_earnings",
        headerName: "Vacation earnings",
        aggFunc: "sumValues",
        hide: true,
        valueFormatter: toDollarFormat,
      },
      {
        field: "holiday_earnings",
        headerName: "Holiday earnings",
        aggFunc: "sumValues",
        hide: true,
        valueFormatter: toDollarFormat,
      },
      {
        field: "other_hourly_earnings",
        headerName: "Other hourly earnings",
        headerTooltip: "PTO, sick, and holiday earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
      {
        field: "non_hourly_earnings",
        headerName: "Non-hourly earnings",
        headerTooltip: "Bonuses, commissions, taxable allowances, and other non-hourly earnings",
        aggFunc: "sumValues",
        valueFormatter: toDollarFormat,
      },
    ];
    return cols.concat(jobHierarchyTableColumns as ColDef<WorkersCompReportRow>[]);
  }, [lookupJob, jobFormatter, jobHierarchyTableColumns]);

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      {!payroll && (
        <Helmet>
          <title>Workers Compensation Report | Miter</title>
        </Helmet>
      )}
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <HeaderTag style={{ marginTop: 0, marginBottom: payroll ? 5 : undefined }}>
          {reportObject!.label}
        </HeaderTag>
      </div>
      <div className="report-page-description">
        {reportObject!.description}
        {!payroll && "*"}
      </div>
      {!payroll && (
        <div style={{ maxWidth: 400 }}>
          <Formblock
            label="Starting payday"
            control={control}
            defaultValue={startDate}
            type="datetime"
            name="start_date"
            max={endDate}
            errors={errors}
            editing={true}
            onChange={onStartDateChange}
            dateOnly
            rules={vals.required}
          />
          <Formblock
            label="Ending payday"
            control={control}
            defaultValue={endDate}
            type="datetime"
            min={startDate}
            name="end_date"
            max={maxAllowableDate}
            errors={errors}
            editing={true}
            onChange={onEndDateChange}
            dateOnly
            rules={vals.required}
          />
        </div>
      )}
      <AgGridTable
        reportId="workers-comp"
        data={data}
        columnDefs={workersCompColumnDefs}
        fileName={fileName}
        csvExportParams={defaultExportParams}
        setGridApi={setGridApi}
        gridOptions={{
          animateRows: true,
          groupDisplayType: "multipleColumns",
          suppressAggFuncInHeader: true,
          defaultCsvExportParams: defaultExportParams,
          defaultExcelExportParams: defaultExportParams,
          showOpenedGroup: true,
        }}
      />
      {!payroll && (
        <div style={{ fontSize: 13, color: "#3C3C3C" }}>
          * Includes hours and earnings on paid, processing, and pending payrolls.
        </div>
      )}
      <div className="vertical-spacer"></div>
    </div>
  );
};

export default CreateWorkersCompReport;

const subjectWagesValueGetter = (params: ValueGetterParams<WorkersCompReportRow>): number => {
  if (!params.data) return 0;
  return roundTo(
    params.data.earnings -
      params.data.ot_earnings / 3 -
      params.data.dot_earnings / 2 -
      params.data.non_hourly_earnings
  );
};
