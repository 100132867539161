import { AggregatedTeamMember, EmployeeBenefit } from "dashboard/miter";
import { checkBenefitCategoryMap } from "dashboard/utils";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { Formblock, Input, Label } from "ui";
import * as vals from "../../utils/validators";
import { UnifiedBenefit, benefitSelections, isBenefitConnectedToExternalSource } from "./benefitsUtils";
import { CheckBenefit, CheckBenefitType } from "backend/utils/check/check-types";
import {
  useActiveCompanyId,
  useCostTypeOptions,
  useLedgerAccountOptions,
  useTeamOptions,
  useVendorOptions,
} from "dashboard/hooks/atom-hooks";
import { employeeFilterWithCheckPredicate } from "../team-members/TeamUtils";
import { Option } from "ui/form/Input";
import { includeWithEarningOption } from "../accounting/accountingUtils";
import { useEmployeeNavigator } from "dashboard/utils/employee-navigator-utils";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import InfoButton from "dashboard/components/information/information";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import { getOtMultiplierTooltip } from "../payrolls/viewPayroll/viewPayrollUtils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type ContributionOptionProps = {
  contributor: "employee" | "company";
  form: UseFormMethods<FieldValues>; // React Hook Form
  benefitToUpdate?: UnifiedBenefit;
  readonly?: boolean;
};

export const hsaContributionLimitOptions: Option<"single" | "family">[] = [
  { label: "Single", value: "single" },
  { label: "Family", value: "family" },
];

const CONTRIBUTION_AMOUNT = "contribution_amount" as const;
const CONTRIBUTION_PERCENT = "contribution_percent" as const;
const PERIOD_AMOUNT = "period_amount" as const;

export const CONTRIBUTION_TYPE_OPTIONS = [
  { label: "$ per payroll", value: CONTRIBUTION_AMOUNT, input_type: "unit" },
  { label: "% of earnings", value: CONTRIBUTION_PERCENT, input_type: "percent" },
  { label: "$ per month", value: PERIOD_AMOUNT, input_type: "unit" },
  { label: "$ per hour", value: "per_hour" as const, input_type: "unit" },
];

export const CONTRIBUTION_TYPE_LABEL_LOOKUP = CONTRIBUTION_TYPE_OPTIONS.reduce(
  (acc, curr) => ({ ...acc, [curr.value]: curr.label }),
  {}
);

export const BenefitContributionOption: React.FC<ContributionOptionProps> = ({
  benefitToUpdate,
  contributor,
  form,
  readonly,
}) => {
  const { register, control, watch, errors } = form;
  const activeCompanyId = useActiveCompanyId();

  // EN syncs over HSA contributions, but doesn't add the ER contribution. Let's
  // make this editable so customers can adjust as needed, since they can't
  // create a separate HSA benefit for the employer portion as Check doesn't
  // allow multiple HSA benefits at this time.
  const isEditableHsaBenefit = benefitToUpdate?.benefit_type === "hsa" && contributor === "company";

  // 401k benefit company contributions are editable if they are connected via Payroll Integrations
  const isEditable401kBenefit =
    (benefitToUpdate?.benefit_type === "401k" || benefitToUpdate?.benefit_type === "roth_401k") &&
    benefitToUpdate?.integrations?.payroll_integrations?.external_id &&
    contributor === "company";

  const isDisabled =
    readonly ||
    (isBenefitConnectedToExternalSource(benefitToUpdate, activeCompanyId) &&
      !isEditableHsaBenefit &&
      !isEditable401kBenefit);

  const formData = watch();

  const [contributionInputType, setContributionInputType] = useState<"unit" | "percent">("unit");

  const selectName = contributor + "_contribution_type";
  const labelInfo = `How much the ${contributor} will contribute toward this benefit.`;

  // Determine default contribution type and value
  const defaultContribution = { amount: "", type: "" };
  if (benefitToUpdate) {
    const {
      company_contribution_amount,
      employee_contribution_amount,
      company_contribution_percent,
      employee_contribution_percent,
      company_period_amount,
      employee_period_amount,
      per_hour_company_contribution,
      per_hour_employee_contribution,
    } = benefitToUpdate;

    if (contributor === "company") {
      defaultContribution.amount =
        company_contribution_amount ||
        company_contribution_percent?.toString() ||
        company_period_amount ||
        "";
      if (company_contribution_amount && per_hour_company_contribution) {
        defaultContribution.type = "per_hour";
      } else if (company_period_amount) {
        defaultContribution.type = PERIOD_AMOUNT;
      } else if (company_contribution_percent) {
        defaultContribution.type = CONTRIBUTION_PERCENT;
      } else {
        defaultContribution.type = CONTRIBUTION_AMOUNT;
      }
    } else {
      defaultContribution.amount =
        employee_contribution_amount ||
        employee_contribution_percent?.toString() ||
        employee_period_amount ||
        "";
      if (employee_contribution_amount && per_hour_employee_contribution) {
        defaultContribution.type = "per_hour";
      } else if (employee_period_amount) {
        defaultContribution.type = PERIOD_AMOUNT;
      } else if (employee_contribution_percent) {
        defaultContribution.type = CONTRIBUTION_PERCENT;
      } else {
        defaultContribution.type = CONTRIBUTION_AMOUNT;
      }
    }
  }

  useEffect(() => {
    setContributionInputType(formData[selectName]?.input_type);
  }, [formData[selectName]]);

  // don't know how to avoid passing in an argument, the signature doesn't align
  const periodCheck = (_v: string) => {
    return vals.neitherOrBothPeriod(
      formData["employee_contribution_type"]?.value,
      formData["company_contribution_type"]?.value
    );
  };

  const benefitTypeSelectionRules = {
    validate: periodCheck,
    required: "This field is required.",
  };

  const benefitContributionInputProps = {
    className: "modal benefit",
    errors: errors,
    defaultValue: Number(defaultContribution.amount),
    register: register(vals.numberValidator({ excludeNegatives: true, required: false, maxDecimals: 2 })),
    name: contributor + "_contribution_value",
    readOnly: isDisabled,
  };

  return (
    <div className="formblock-wrapper modal">
      <Label labelInfo={labelInfo} className="modal" label={capitalize(contributor) + " contribution"} />
      <div className="flex" style={{ alignItems: "flex-start", width: "100%" }}>
        <Input
          className="modal"
          type="select"
          options={CONTRIBUTION_TYPE_OPTIONS}
          defaultValue={defaultContribution.type || CONTRIBUTION_AMOUNT}
          control={control}
          requiredSelect={true}
          name={selectName}
          errors={errors}
          disabled={isDisabled}
          rules={benefitTypeSelectionRules}
        />
        <div style={{ width: 30 }} />
        {contributionInputType === "percent" ? (
          <Input {...benefitContributionInputProps} type="percent" />
        ) : (
          <Input {...benefitContributionInputProps} type="unit" unit="$" />
        )}
      </div>
    </div>
  );
};

type BenefitFormFieldsProps = {
  form: UseFormMethods<FieldValues>; // React Hook Form;
  benefitToUpdate?: UnifiedBenefit;
  employee?: AggregatedTeamMember;
  isEmployeeBenefit: boolean;
  readonly?: boolean;
};

export const BenefitFormFields: React.FC<BenefitFormFieldsProps> = ({
  form,
  benefitToUpdate,
  employee,
  isEmployeeBenefit,
  readonly,
}) => {
  const { register, control, errors, trigger, watch, setValue } = form;

  const activeCompanyId = useActiveCompanyId();
  const benefitAbilities = useEmployeeBenefitAbilities();
  const vendorOptions = useVendorOptions();
  const { cannot } = useMiterAbilities();

  const formData = watch();

  const finalPredicate = useCallback(
    (ee: AggregatedTeamMember) => {
      return (
        (benefitAbilities.teamPredicate(benefitToUpdate ? "update" : "create")(ee) &&
          employeeFilterWithCheckPredicate(ee)) ||
        employee?._id === ee?._id
      );
    },
    [benefitAbilities, benefitToUpdate]
  );

  const employeeOptions = useTeamOptions({ predicate: finalPredicate });

  const integrationLookup = useHasIntegration();
  const hasContractorsPlan = integrationLookup.has("contractors_plan");
  const hasEmployeeNavigator = integrationLookup.has("employee_navigator");

  const { enBenefitDeductionCodeOptions } = useEmployeeNavigator();

  const [startDate, setStartDate] = useState<DateTime | undefined>(
    benefitToUpdate?.effective_start ? DateTime.fromISO(benefitToUpdate.effective_start) : undefined
  );
  const [endDate, setEndDate] = useState<DateTime | undefined>(
    benefitToUpdate?.effective_end ? DateTime.fromISO(benefitToUpdate.effective_end) : undefined
  );
  const [benefitTypeSelection, setBenefitTypeSelection] = useState(
    benefitSelections.find((o) => o.value === benefitToUpdate?.benefit_type)
  );
  const [otMultipliers, setOtMultipliers] = useState(benefitToUpdate?.ot_multipliers);
  const [createAutoEarning, setCreateAutoEarning] = useState(!!benefitToUpdate?.group_term_life_amount);

  const ledgerAccountOptions = useLedgerAccountOptions();
  const expenseAccountOptions = useLedgerAccountOptions({ includeCustomOption: includeWithEarningOption });
  const costTypeOptions = useCostTypeOptions();

  const handleStartChange = (dt) => {
    setStartDate(dt);
  };

  const handleEndChange = (dt) => {
    setEndDate(dt);
  };

  const handleIsOtEligibleChange = (isOtEligible: boolean) => {
    if (!isOtEligible) {
      setOtMultipliers(undefined);
    } else if (!otMultipliers && isOtEligible) {
      setOtMultipliers({ ot: 1.5, dot: 2 });
    }
  };

  const handleOtMultiplierChange = (e) => {
    const timeTypeKey = e.target.name.split("_")[0];
    const value = e.target.value;
    if (["ot", "dot"].includes(timeTypeKey) && (value || value === "0")) {
      setOtMultipliers((prev) => ({ ...prev, [timeTypeKey]: value }));
    }
  };

  useEffect(() => {
    trigger("effective_end");
  }, [startDate, endDate]);

  useEffect(() => {
    setValue("ot_multipliers", otMultipliers);
  }, [otMultipliers]);

  const validateEndDate = (effective_end: DateTime) => {
    let effective_start = startDate || undefined;
    let errMessage: string | undefined;

    effective_start = startDate || undefined;
    if (!startDate) {
      errMessage = "Since you haven't given a start date, end date must be blank or on/after today.";
    }

    return vals.isValidEndDate(effective_start?.toFormat("DD"), effective_end?.toFormat("DD"), errMessage);
  };

  // If benefit has an external source of truth, then editing from within Miter should be disabled
  const isDisabled = readonly || isBenefitConnectedToExternalSource(benefitToUpdate, activeCompanyId);

  const companyContributionIsPerPayroll = formData.company_contribution_type?.value
    ? formData.company_contribution_type.value === CONTRIBUTION_AMOUNT
    : !!benefitToUpdate?.company_contribution_amount && !benefitToUpdate?.per_hour_company_contribution;

  const companyContributionIsMonthly = formData.company_contribution_type?.value
    ? formData.company_contribution_type.value === PERIOD_AMOUNT
    : !!benefitToUpdate?.company_period_amount;

  const companyContributionIsPerPayrollOrMonthly =
    companyContributionIsPerPayroll || companyContributionIsMonthly;

  const companyContributionIsHourly = formData.company_contribution_type?.value
    ? formData.company_contribution_type.value === "per_hour"
    : !!benefitToUpdate?.per_hour_company_contribution;

  const employeeContributionIsHourly = formData.employee_contribution_type?.value
    ? formData.employee_contribution_type.value === "per_hour"
    : !!benefitToUpdate?.per_hour_employee_contribution;

  const renderLeftColumn = () => {
    return (
      <div className="modal-form-column">
        {isEmployeeBenefit && (
          <Formblock
            label="Employee"
            type="select"
            options={employeeOptions}
            control={form.control}
            name="employee"
            className="modal"
            editing={true}
            defaultValue={employee?._id}
            disabled={!!employee || readonly}
          />
        )}
        <Formblock
          name="benefit_type"
          type="select"
          options={benefitSelections}
          errors={errors}
          defaultValue={benefitToUpdate?.benefit_type}
          value={benefitTypeSelection}
          label="Benefit type"
          disabled={benefitToUpdate || readonly ? true : false}
          editing={true}
          control={control}
          className="modal"
          requiredSelect
          onChange={setBenefitTypeSelection}
        />
        <BenefitContributionOption
          benefitToUpdate={benefitToUpdate}
          contributor="employee"
          form={form}
          readonly={readonly}
        />
        <BenefitContributionOption
          benefitToUpdate={benefitToUpdate}
          contributor="company"
          form={form}
          readonly={readonly}
        />
        <Formblock
          type="datetime"
          control={control}
          dateOnly
          errors={errors}
          defaultValue={startDate}
          onChange={handleStartChange}
          name="effective_start"
          label="Effective start"
          className="modal"
          editing={true}
          topOfInput={false}
          max={endDate}
          labelInfo="The date this benefit will be considered effective. Payrolls with a payday before this date will not include the benefit."
          disabled={isDisabled}
          style={{ zIndex: 100 }}
        />
        <Formblock
          type="datetime"
          control={control}
          dateOnly
          rules={{ validate: validateEndDate }}
          errors={errors}
          defaultValue={endDate}
          onChange={handleEndChange}
          name="effective_end"
          min={startDate}
          editing={true}
          label="Effective end"
          className="modal"
          topOfInput={true}
          labelInfo="The date this benefit will no longer be considered effective. Payrolls with a payday after this date will not include the benefit."
          disabled={isDisabled}
          style={{ zIndex: 100 }}
        />
        <Formblock
          name="plan_number"
          type="text"
          label="Plan number (optional)"
          labelInfo="Plan number associated with this benefit"
          errors={errors}
          defaultValue={benefitToUpdate?.plan_number}
          editing={true}
          register={register}
          className="modal"
        />
        <Formblock
          name="vendor_id"
          type="select"
          options={vendorOptions}
          defaultValue={benefitToUpdate?.vendor_id}
          errors={errors}
          label="Bill pay vendor"
          editing={true}
          control={control}
          className="modal"
          labelInfo="If specified, a bill to this vendor will be generated per payroll."
          isClearable
          disabled={readonly}
          hidden={cannot("bill_pay:bills:create")}
        />
      </div>
    );
  };

  const renderRightColumn = () => {
    return (
      <div className="modal-form-column">
        <Formblock
          name="description"
          type="text"
          errors={errors}
          label="Description"
          defaultValue={benefitToUpdate?.description}
          editing={true}
          register={register(vals.required)}
          className="modal"
        />
        {hasEmployeeNavigator && (
          <Formblock
            label="Deduction code"
            type="select"
            options={enBenefitDeductionCodeOptions}
            control={form.control}
            name="integrations.employee_navigator.deduction_code"
            className="modal"
            editing={true}
            defaultValue={benefitToUpdate?.integrations?.employee_navigator?.deduction_code}
            labelInfo="The deduction code used to identify this benefit in Employee Navigator."
            disabled={readonly}
          />
        )}
        <Formblock
          name="emp_liab_account_id"
          type="select"
          options={ledgerAccountOptions}
          errors={errors}
          defaultValue={benefitToUpdate?.emp_liab_account_id}
          label="Employee liability account"
          editing={true}
          control={control}
          className="modal"
          labelInfo={
            "Miter will credit this account for the employee contribution when creating payroll GL entries."
          }
          isClearable
          disabled={readonly}
        />
        <Formblock
          name="com_liab_account_id"
          type="select"
          options={ledgerAccountOptions}
          errors={errors}
          defaultValue={benefitToUpdate?.com_liab_account_id}
          label="Company liability account"
          editing={true}
          control={control}
          className="modal"
          labelInfo={
            "Miter will credit this account for the company contribution when creating payroll GL entries."
          }
          isClearable
          disabled={readonly}
        />
        <Formblock
          name="expense_account_id"
          type="select"
          options={expenseAccountOptions}
          errors={errors}
          defaultValue={benefitToUpdate?.expense_account_id}
          label="Company expense account"
          editing={true}
          control={control}
          className="modal"
          labelInfo={
            "Miter will debit this account for the company contribution when creating payroll GL entries."
          }
          isClearable
          disabled={readonly}
        />
        <Formblock
          name="cost_type_id"
          type="select"
          options={costTypeOptions}
          errors={errors}
          defaultValue={benefitToUpdate?.cost_type_id}
          label="Cost type"
          editing={true}
          control={control}
          className="modal"
          labelInfo={`Miter will use this cost type for the company contribution instead of the default "Indirect" cost type.`}
          isClearable
          disabled={readonly}
        />
        {benefitTypeSelection?.value === "hsa" && (
          <Formblock
            name="hsa_contribution_limit"
            type="select"
            options={hsaContributionLimitOptions}
            errors={errors}
            defaultValue={benefitToUpdate?.hsa_contribution_limit || employee?.hsa_limit || "single"}
            label="Contribution limit"
            editing={true}
            control={control}
            className="modal"
            disabled={readonly}
          />
        )}
        <Formblock
          name="non_bonafide"
          type="checkbox"
          text="This benefit should not offset prevailing wage fringe rates."
          errors={errors}
          defaultValue={benefitToUpdate?.non_bonafide}
          editing={true}
          register={register}
          className="modal"
          disabled={readonly}
        />
        {companyContributionIsHourly || employeeContributionIsHourly ? (
          <Formblock
            type="checkbox"
            text={renderOTEligibleText()}
            name="ot_multipliers"
            style={{ marginTop: -7 }}
            checked={!!otMultipliers}
            onChange={(e) => handleIsOtEligibleChange(e.target.checked)}
            form={form}
            editing={true}
            register={register}
            className="modal"
            disabled={readonly}
          />
        ) : null}
        {!!otMultipliers && (
          <div className="flex">
            <Formblock
              type="text"
              control={control}
              label="OT multiplier"
              labelInfo={getOtMultiplierTooltip("ot", "fringes")}
              form={form}
              defaultValue={otMultipliers?.ot?.toString()}
              onChange={handleOtMultiplierChange}
              name="ot_multiplier"
              editing={true}
              className="modal"
              disabled={readonly}
              register={register(vals.isNonNegativeNumberButNotRequired)}
              errors={errors}
            />
            <div style={{ width: 15 }}></div>
            <Formblock
              type="text"
              control={control}
              label="DOT multiplier"
              labelInfo={getOtMultiplierTooltip("dot", "fringes")}
              form={form}
              defaultValue={otMultipliers?.dot?.toString()}
              onChange={handleOtMultiplierChange}
              name="dot_multiplier"
              editing={true}
              className="modal"
              disabled={readonly}
              register={register(vals.isNonNegativeNumberButNotRequired)}
              errors={errors}
            />
          </div>
        )}
        {hasContractorsPlan &&
          checkBenefitCategoryMap[formData.benefit_type?.value || ""] === "retirement" && (
            <Formblock
              name="is_contractors_plan"
              type="checkbox"
              text="Report contributions to The Contractors' Plan."
              errors={errors}
              defaultValue={benefitToUpdate?.integrations?.contractors_plan?.is_cp_benefit}
              editing={true}
              register={register}
              className="modal"
              disabled={readonly}
            />
          )}
        {benefitTypeSelection?.value === "125_life" && companyContributionIsPerPayrollOrMonthly && (
          <Formblock
            name="create_auto_life_earnings"
            type="checkbox"
            label="Create imputed earning"
            labelInfo="Automatically create imputed employee earning for this benefit within regular payrolls."
            text="Yes, create an earning for this benefit."
            errors={errors}
            defaultValue={!!createAutoEarning}
            editing={true}
            register={register}
            onChange={(e) => setCreateAutoEarning(e.target.checked)}
            className="modal"
            disabled={readonly}
          />
        )}
        {createAutoEarning && companyContributionIsPerPayrollOrMonthly && (
          <Formblock
            label={`Earning amount per ${companyContributionIsPerPayroll ? "payroll" : "month"}`}
            labelInfo="Please refer to the 'Group-Term Life Insurance Coverage' section of IRS publication 15-B when determining this number."
            tooltipPlace="top"
            type="unit"
            unit="$"
            register={register(
              vals.numberValidator({ excludeNegatives: true, excludeZero: true, maxDecimals: 2 })
            )}
            name="group_term_life_amount"
            className="modal benefit"
            defaultValue={benefitToUpdate?.group_term_life_amount}
            errors={errors}
            editing
            disabled={readonly}
          />
        )}
      </div>
    );
  };

  const renderOTEligibleText = () => {
    return (
      <div className="flex">
        <span>This benefit is OT eligible</span>
        <InfoButton text="If checked, Miter will multiply eligible per-hour company and employee contributions by these multipliers." />
      </div>
    );
  };

  return (
    <>
      {renderLeftColumn()}
      {renderRightColumn()}
    </>
  );
};

type RawBenefitFormData = {
  employee_contribution_type: {
    value: typeof CONTRIBUTION_AMOUNT | typeof CONTRIBUTION_PERCENT | typeof PERIOD_AMOUNT | "per_hour";
    label: string;
    input_type: "unit" | "percent";
  };
  employee_contribution_value: string;
  company_contribution_type: {
    value: typeof CONTRIBUTION_AMOUNT | typeof CONTRIBUTION_PERCENT | typeof PERIOD_AMOUNT | "per_hour";
    label: string;
    input_type: "unit" | "percent";
  };
  company_contribution_value: string;
  description: string;
  effective_start?: DateTime;
  effective_end?: DateTime;
  non_bonafide?: boolean;
  ot_multiplier?: string;
  dot_multiplier?: string;
  plan_number?: string;
  hsa_contribution_limit?: {
    value: CheckBenefit["hsa_contribution_limit"];
    label: string;
  };
  benefit_type: {
    value: CheckBenefitType;
    label: string;
  };
  employee?: {
    value: string;
    label: string;
  };
  emp_liab_account_id?: {
    value: string;
    label: string;
  };
  com_liab_account_id?: {
    value: string;
    label: string;
  };
  expense_account_id?: {
    value: string;
    label: string;
  };
  cost_type_id?: {
    value: string;
    label: string;
  };
  is_contractors_plan?: boolean;
  create_auto_life_earnings?: boolean;
  group_term_life_amount?: string | null;
  integrations?: {
    employee_navigator?: {
      deduction_code?: Option<string>;
    };
  };
  vendor_id?: {
    value: string;
    label: string;
  };
};

type CleanedBenefitFormData = {
  employee?: string; // Miter employee _id
  benefit: CheckBenefitType;
  company: string;
  description: string;
  effective_start?: string | null;
  effective_end?: string | null;
  company_contribution_amount?: string | null;
  company_contribution_percent?: number | null;
  company_period_amount?: string | null;
  employee_contribution_amount?: string | null;
  employee_contribution_percent?: number | null;
  employee_period_amount?: string | null;
  period?: "monthly" | null;
  plan_number?: string;
  non_bonafide?: boolean;
  ot_multipliers?: { ot?: number; dot?: number } | null;
  hsa_contribution_limit?: CheckBenefit["hsa_contribution_limit"];
  per_hour_employee_contribution?: boolean;
  per_hour_company_contribution?: boolean;
  integrations?: EmployeeBenefit["integrations"];
  com_liab_account_id?: string | null;
  emp_liab_account_id?: string | null;
  expense_account_id?: string | null;
  cost_type_id?: string | null;
  group_term_life_amount?: number | null;
  vendor_id?: string | null;
};

type useCleanBenefitFormData = () => (
  rawData: RawBenefitFormData,
  companyId: string,
  employeeId?: string,
  benefitToUpdate?: UnifiedBenefit
) => CleanedBenefitFormData;

export const useCleanBenefitFormData: useCleanBenefitFormData = () => {
  const { enIntegration } = useEmployeeNavigator();

  const cleanBenefitFormData = (
    rawData: RawBenefitFormData,
    companyId: string,
    employeeId?: string,
    benefitToUpdate?: UnifiedBenefit
  ): CleanedBenefitFormData => {
    let companyContributionAmount: string | undefined,
      companyContributionPercent: number | undefined,
      companyContributionPeriod: string | undefined,
      employeeContributionAmount: string | undefined,
      employeeContributionPercent: number | undefined,
      employeeContributionPeriod: string | undefined,
      period: "monthly" | null = null,
      perHourEmployeeContribution = false,
      perHourCompanyContribution = false,
      groupTermLifeEarningEligible = false;

    if (rawData.company_contribution_type.value === "per_hour") {
      perHourCompanyContribution = true;
      companyContributionAmount = rawData.company_contribution_value;
    } else if (rawData.company_contribution_type.value === CONTRIBUTION_AMOUNT) {
      groupTermLifeEarningEligible = true;
      companyContributionAmount = rawData.company_contribution_value;
    } else if (rawData.company_contribution_type.value === CONTRIBUTION_PERCENT) {
      companyContributionPercent = Number(rawData.company_contribution_value);
    } else {
      groupTermLifeEarningEligible = true;
      companyContributionPeriod = rawData.company_contribution_value;
    }

    if (rawData.employee_contribution_type.value === "per_hour") {
      employeeContributionAmount = rawData.employee_contribution_value;
      perHourEmployeeContribution = true;
    } else if (rawData.employee_contribution_type.value === CONTRIBUTION_AMOUNT) {
      employeeContributionAmount = rawData.employee_contribution_value;
    } else if (rawData.employee_contribution_type.value === CONTRIBUTION_PERCENT) {
      employeeContributionPercent = Number(rawData.employee_contribution_value);
    } else {
      employeeContributionPeriod = rawData.employee_contribution_value;
    }

    if (
      rawData.employee_contribution_type.value === PERIOD_AMOUNT &&
      rawData.company_contribution_type.value === PERIOD_AMOUNT
    ) {
      period = "monthly";
    }

    const effectiveStart = rawData.effective_start ? rawData.effective_start?.toISODate() : null;
    const effectiveEnd = rawData.effective_end ? rawData.effective_end?.toISODate() : null;

    // This is a bit complex, but there are a lot of cases we need to handle
    // with input here. When changing this, consider:
    // - NaN values (forbidden)
    // - Negative values (forbidden)
    // - Null values (removed)
    // - Empty string values (removed)
    // - Empty object overall (removed)
    // - 0 values (should be preserved)
    const otMultipliers = {
      ot: rawData.ot_multiplier,
      dot: rawData.dot_multiplier,
    };
    let cleanedOtMultipliers = Object.entries(otMultipliers ?? {}).reduce((obj, [key, value]) => {
      if (value == null || !obj || isNaN(parseFloat(value))) return obj;
      obj[key] = parseFloat(value);
      return obj;
    }, {} as EmployeeBenefit["ot_multipliers"]);
    if (Object.keys(cleanedOtMultipliers ?? {}).length === 0) {
      cleanedOtMultipliers = null;
    }

    const ret: CleanedBenefitFormData = {
      employee: employeeId || rawData.employee?.value,
      benefit: rawData.benefit_type.value,
      description: rawData.description,
      company: companyId,
      company_contribution_amount: companyContributionAmount || null,
      company_contribution_percent: companyContributionPercent || null,
      company_period_amount: companyContributionPeriod || null,
      employee_contribution_amount: employeeContributionAmount || null,
      employee_contribution_percent: employeeContributionPercent || null,
      employee_period_amount: employeeContributionPeriod || null,
      period: period || null,
      effective_start: effectiveStart || DateTime.now().toISODate(),
      effective_end: effectiveEnd,
      per_hour_company_contribution: perHourCompanyContribution,
      per_hour_employee_contribution: perHourEmployeeContribution,
      non_bonafide: rawData.non_bonafide,
      ot_multipliers: cleanedOtMultipliers,
      plan_number: rawData.plan_number,
      hsa_contribution_limit: rawData.hsa_contribution_limit?.value,
      emp_liab_account_id: rawData.emp_liab_account_id?.value || null,
      com_liab_account_id: rawData.com_liab_account_id?.value || null,
      expense_account_id: rawData.expense_account_id?.value || null,
      cost_type_id: rawData.cost_type_id?.value || null,
      group_term_life_amount:
        (rawData.create_auto_life_earnings &&
          groupTermLifeEarningEligible &&
          Number(rawData.group_term_life_amount)) ||
        null,
      vendor_id: rawData.vendor_id?.value || null,
    };

    if (benefitToUpdate?.integrations || rawData.is_contractors_plan) {
      ret.integrations = {
        ...benefitToUpdate?.integrations,
        contractors_plan: { is_cp_benefit: !!rawData.is_contractors_plan },
      };
    }

    if (
      enIntegration &&
      enIntegration.connection &&
      rawData.integrations?.employee_navigator?.deduction_code
    ) {
      ret.integrations = {
        ...ret.integrations,
        employee_navigator: {
          integration_connection_id: enIntegration.connection?._id,
          deduction_code: rawData.integrations?.employee_navigator?.deduction_code?.value,
          connected: benefitToUpdate?.integrations?.employee_navigator?.connected || false,
        },
      };
    }

    return ret;
  };

  return cleanBenefitFormData;
};
