import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  MiterAPI,
  TimeOffPolicyLevelConfig,
  TimeOffPolicy,
  TimeOffEnrolleesTableEntry,
} from "dashboard/miter";

import styles from "./TimeOffPolicies.module.css";
import { Breadcrumbs, ConfirmModal, DropdownButton, Notifier, Toggler } from "ui";
import { Helmet } from "react-helmet";
import { capitalize } from "lodash";
import { CaretDown, Pencil, TrashSimple } from "phosphor-react";
import { TimeOffRequestsTable } from "./TimeOffRequestsTable";
import TimeOffUpdatesTable from "./TimeOffUpdatesTable";
import { buildAccrualType } from "./TimeOffPolicies";
import DataBox from "dashboard/components/dataBox/DataBox";
import TimeOffPolicyEnrolleesTable, {
  EnrollmentFailureTeamMember,
} from "dashboard/components/time-off/TimeOffPolicyEnrolleesTable";
import { buildTenureString, notNullish } from "miter-utils";
import { useRefetchTeam, useLookupTeam, useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import FailuresModal from "dashboard/components/shared/FailuresModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TRUEBECK_COMPANY_IDS } from "dashboard/utils";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { TimeOffPolicyForm } from "./TimeOffPolicyForm";

const TimeOffPolicyPage: React.FC = () => {
  const { id, view } = useParams<{ id: string; view?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const levelId = searchParams.get("levelId");

  const [timeOffPolicy, setTimeOffPolicy] = useState<TimeOffPolicy>();
  const { can, cannot } = useMiterAbilities();
  const activeCompanyId = useActiveCompanyId();

  const navigate = useNavigate();
  const refetchTeamMembers = useRefetchTeam();
  const lookupTeam = useLookupTeam();

  /*********************************************************
   * States
   **********************************************************/

  const [showEditingScreen, setShowEditingScreen] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [enrollmentFailures, setEnrollmentFailures] = useState<EnrollmentFailureTeamMember[]>([]);

  const [archiving, setArchiving] = useState(false);

  /*********************************************************
   *  Important consts
   **********************************************************/
  useEffect(() => {
    getTimeOffPolicy();
    refetchTeamMembers();
  }, []);

  useEffect(() => {
    if (!view) {
      navigate(`/time-off/policies/${id}/enrollees`, { replace: true });
    }
  }, [timeOffPolicy]);

  /*********************************************************
   *  API Functions
   **********************************************************/
  const getTimeOffPolicy = async () => {
    try {
      if (!id) throw new Error("ID is required to find time off policy");
      const res = await MiterAPI.time_off.policies.retrieve(id);
      if (res.error) throw new Error(res.error);

      setTimeOffPolicy(res);
    } catch (e: $TSFixMe) {
      console.error("Unable to get time off policy", e);
      Notifier.error("We were unable to get this time off policy. Please contact suport");
    }
  };

  const editSection = (section: string) => {
    if (can("time_off:policies:update")) {
      setShowEditingScreen(true);
      setSearchParams({ levelId: levelId || "", section });
    }
  };

  const archiveTimeOffPolicy = async () => {
    if (!timeOffPolicy || cannot("time_off:policies:delete")) return;
    setArchiving(true);

    try {
      const response = await MiterAPI.time_off.policies.delete(timeOffPolicy?._id);
      if (response.error) throw Error(response.error);

      Notifier.success("Time off policy deleted");
      navigate("/time-off/policies");
    } catch (e: $TSFixMe) {
      if (e.message === "Time off policy cannot be deleted because there is unpaid time off.") {
        Notifier.error(
          "Time off policy cannot be deleted because there are unpaid time off requests.\nIf you would like to delete this policy, please delete those time off requests in the time off module.",
          { style: { minWidth: "800px", whiteSpace: "pre-line" }, duration: 10000 }
        );
      } else {
        Notifier.error("There was an error deleting the time off policy. We're looking into it.");
      }
    }

    setArchiving(false);
  };

  const saveEnrollees = useCallback(
    async (employeeList: TimeOffEnrolleesTableEntry[]) => {
      if (!timeOffPolicy) return;

      const updatedEmployees = employeeList.filter((employee) => employee.updated);
      try {
        const res = await MiterAPI.time_off.policies.update_enrollees(timeOffPolicy._id, updatedEmployees);

        if (res.error) throw new Error(res.error);
        if (res.failures?.length) {
          const failures = res.failures
            .map((error) => {
              const employee = lookupTeam(error.id);
              if (!employee) return;
              return { ...employee, failure_reason: error.message };
            })
            .filter(notNullish);

          setEnrollmentFailures(failures);

          throw new Error("Some employees could not be enrolled. See the modal for more details.");
        }

        Notifier.success("Enrolled employees successfully updated");
      } catch (e: $TSFixMe) {
        if (e.message !== "Some employees could not be enrolled. See the modal for more details.") {
          console.error("Error updating enrolled employees", e);
        }

        Notifier.error(e.message);
      }

      refetchTeamMembers(updatedEmployees.map((employee) => employee._id));
    },
    [timeOffPolicy?._id, lookupTeam]
  );

  /*********************************************************
   *  Toggler config
   **********************************************************/
  const handleToggle = (option) => {
    if (!timeOffPolicy) return;

    if (option === "details") {
      navigate({
        pathname: "/time-off/policies/" + timeOffPolicy._id + "/details",
        search: "?levelId=" + timeOffPolicy.levels[0]?._id.toString(),
      });
    } else {
      navigate("/time-off/policies/" + timeOffPolicy._id + "/" + option);
    }
  };

  const handleLevelToggle = (option) => {
    if (!timeOffPolicy) return;
    navigate("/time-off/policies/" + timeOffPolicy._id + "/details?levelId=" + option);
  };

  /*********************************************************
   *  Render Functions
   **********************************************************/

  const togglerConfig = [
    {
      path: "enrollees",
      label: "Enrollees",
    },
    {
      path: "requests",
      label: "Requests",
    },

    {
      path: "updates",
      label: "Updates",
    },
    {
      path: "details",
      label: "Details",
    },
  ];

  const togglerLevelConfig = useMemo(() => {
    const toggler: { path: string; label: string }[] = [];

    timeOffPolicy?.levels?.map((level) => {
      toggler.push({
        path: level._id,
        label: level.name,
      });
    });

    return toggler;
  }, [timeOffPolicy]);

  const renderBreadcrumbs = () => {
    if (!timeOffPolicy) return;

    return (
      <Breadcrumbs
        crumbs={[
          {
            label: "Time Off Policies",
            path: "/time-off/policies",
          },
          {
            label: timeOffPolicy.name || "Untitled Time Off Policy",
            path: "/time-off/policies/" + timeOffPolicy._id + "/enrollees",
          },
        ]}
      />
    );
  };

  const renderEnrollmentFailuresModal = () => {
    if (!enrollmentFailures.length) return;

    const failures = enrollmentFailures.map((failure) => ({
      label: failure.full_name,
      message: failure.failure_reason,
    }));

    return (
      <FailuresModal
        headerText={"Enrollment failures"}
        onClose={() => setEnrollmentFailures([])}
        failures={failures}
      />
    );
  };

  const renderActions = () => {
    if (!timeOffPolicy) return;

    const actions = [
      {
        label: "Edit time off policy",
        action: () => setShowEditingScreen(true),
        icon: <Pencil style={{ marginBottom: -3, marginRight: 7 }} />,
        shouldShow: () => can("time_off:policies:update"),
      },
      {
        label: "Delete time off policy",
        action: () => setShowArchiveModal(true),
        icon: <TrashSimple style={{ marginBottom: -3, marginRight: 7 }} />,
        shouldShow: () => can("time_off:policies:delete"),
      },
    ];

    return (
      <div className={styles["actions"]}>
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
    );
  };

  const renderDetails = (levelConfig?: TimeOffPolicyLevelConfig, timeOffPolicy?: TimeOffPolicy) => {
    if (!levelConfig || !timeOffPolicy) return;

    const basicDetailsList = [
      {
        label: "Name",
        value: levelConfig.name,
      },
      {
        label: "Paid",
        value: levelConfig.unpaid ? "No" : "Yes",
      },
      {
        label: "Auto-enrollment tenure threshold",
        value: buildTenureString(levelConfig.auto_enrollment?.tenure_threshold || {}),
      },
      {
        label: "Accrual type",
        value: capitalize(buildAccrualType(levelConfig)),
      },
      ...((activeCompanyId && TRUEBECK_COMPANY_IDS.includes(activeCompanyId)) || !IS_PRODUCTION
        ? [
            {
              label: "Custom earning code (optional)",
              value: timeOffPolicy.custom_earning_code || "-",
            },
          ]
        : []),
    ];

    const advancedDetailsList = [
      {
        label: "Minimum tenure for requests",
        value: levelConfig.min_tenure_for_requests ?? "-",
      },
      {
        label: "Minimum tenure for accruals",
        value: levelConfig.min_tenure_for_accruals ?? "-",
      },
      {
        label: "Disable negative balances",
        value: levelConfig.disable_negative_balances ? "Yes" : "No",
      },

      {
        label: "Enable prevailing wage fringe offset",
        value: levelConfig.enable_fringe_offset ? "Yes" : "No",
      },
    ];

    if (levelConfig.accrual_config?.hourly) {
      advancedDetailsList.push({
        label: "Accrue on Davis-Bacon jobs",
        value: timeOffPolicy.accrue_on_davis_bacon_jobs ? "Yes" : "No",
      });
    }

    const balanceDetailsList = [
      {
        label: "Default starting balance",
        value: levelConfig.default_starting_balance ?? "-",
      },
    ];

    const carryOverSettings = [
      {
        label: "Carryover previous time off balance when enrolled into this level",
        value:
          levelConfig.auto_enrollment?.carryover_type === "replace"
            ? "Default the new balance to unused hours"
            : levelConfig.auto_enrollment?.carryover_type === "add"
            ? "Add unused hours to new balance"
            : "No",
      },
    ];

    if (levelConfig.accrual_config) {
      balanceDetailsList.push(
        ...[
          {
            label: "Yearly accrual limit (calendar year)",
            value: levelConfig.accrual_config?.yearly_limit ?? "-",
          },
          {
            label: "Max balance",
            value: levelConfig.accrual_config?.max_balance ?? "-",
          },
        ]
      );
      carryOverSettings.push({
        label: "Yearly carryover limit",
        value: levelConfig.accrual_config?.carryover_limit?.toString() || "-",
      });
    }

    const hourlyDetailsList: { label: string; value: string }[] = [];
    if (levelConfig.accrual_config?.hourly) {
      hourlyDetailsList.push(
        ...[
          {
            label: "Only count hours worked",
            value: !levelConfig.accrual_config.hourly.count_hours_paid ? "Yes" : "No",
          },
          {
            label: "Count overtime",
            value: levelConfig.accrual_config.hourly.count_overtime ? "Yes" : "No",
          },
          {
            label: "Hourly rate",
            value: `${levelConfig.accrual_config.hourly.rate.hours_earned} hours earned for every ${levelConfig.accrual_config.hourly.rate.hours_based_on} hours worked`,
          },
        ]
      );
    }

    const fixedDetailsList: { label: string; value: string | number }[] = [];
    if (levelConfig.accrual_config?.fixed) {
      const accrualDate = levelConfig.accrual_config.fixed.accrual_date;
      const accrualDateString = accrualDate ? accrualDate.month + "/" + accrualDate.day : "-";

      fixedDetailsList.push(
        ...[
          {
            label: "Total hours per year",
            value: levelConfig.accrual_config.fixed.total_hours,
          },
          {
            label: "Accrual cadence",
            value: capitalize(levelConfig.accrual_config.fixed.cadence),
          },
          ...(levelConfig.accrual_config.fixed.cadence === "yearly"
            ? [
                {
                  label: "Accrual date type",
                  value: capitalize(levelConfig.accrual_config.fixed.accrual_date_type?.replaceAll("_", " ")),
                },
              ]
            : []),

          ...(accrualDate
            ? [
                {
                  label: "Accrual date",
                  value: accrualDateString,
                },
              ]
            : []),
        ]
      );
    }

    return (
      <>
        <div className="vertical-spacer" />

        <DataBox title={"Basics"} onEdit={() => editSection("basicSettings")}>
          {basicDetailsList.map((detail, index) => (
            <div className={"data-box-section"} key={"detail-" + index}>
              <span className={"data-box-section-title font-14"}>{detail.label}</span>
              <span className={"data-box-section-value font-14"}>{detail.value}</span>
            </div>
          ))}
        </DataBox>
        {fixedDetailsList.length > 0 && (
          <DataBox title={"Fixed accrual info"} onEdit={() => editSection("accrualSettings")}>
            {fixedDetailsList.map((detail, index) => (
              <div className={"data-box-section"} key={"detail-" + index}>
                <span className={"data-box-section-title font-14"}>{detail.label}</span>
                <span className={"data-box-section-value font-14"}>{detail.value}</span>
              </div>
            ))}
          </DataBox>
        )}
        {hourlyDetailsList.length > 0 && (
          <DataBox title={"Hourly accrual info"} onEdit={() => editSection("accrualSettings")}>
            {hourlyDetailsList.map((detail, index) => (
              <div className={"data-box-section"} key={"detail-" + index}>
                <span className={"data-box-section-title font-14"}>{detail.label}</span>
                <span className={"data-box-section-value font-14"}>{detail.value}</span>
              </div>
            ))}
          </DataBox>
        )}
        {!levelConfig.unlimited && (
          <>
            <DataBox title={"Balance configuration"} onEdit={() => editSection("balanceSettings")}>
              {balanceDetailsList.map((detail, index) => (
                <div className={"data-box-section"} key={"detail-" + index}>
                  <span className={"data-box-section-title font-14"}>{detail.label}</span>
                  <span className={"data-box-section-value font-14"}>{detail.value}</span>
                </div>
              ))}
            </DataBox>
            <DataBox title={"Carryover settings"} onEdit={() => editSection("carryoverSettings")}>
              {carryOverSettings.map((detail, index) => (
                <div className={"data-box-section"} key={"detail-" + index}>
                  <span className={"data-box-section-title font-14"}>{detail.label}</span>
                  <span className={"data-box-section-value font-14"}>{detail.value}</span>
                </div>
              ))}
            </DataBox>
          </>
        )}
        {!levelConfig.unlimited && (
          <DataBox title={"Advanced settings"} onEdit={() => editSection("advancedSettings")}>
            {advancedDetailsList.map((detail, index) => (
              <div className={"data-box-section"} key={"detail-" + index}>
                <span className={"data-box-section-title font-14"}>{detail.label}</span>
                <span className={"data-box-section-value font-14"}>{detail.value}</span>
              </div>
            ))}
          </DataBox>
        )}
      </>
    );
  };

  const renderContent = () => {
    // if (loading) return <Loader />;
    if (!timeOffPolicy) return;

    const subheader = `${capitalize(timeOffPolicy.type)} Policy`;

    return (
      <>
        <div className={"page-content-header " + styles["header"]} style={{ marginTop: 5 }}>
          {renderBreadcrumbs()}
          <h1 className="view-title" style={{ marginTop: 10 }}>
            {timeOffPolicy.name || "Untitled Time Off Policy"}
          </h1>
          <span className={styles["subheader"]}>{subheader}</span>
          {renderActions()}
        </div>
        <Toggler config={togglerConfig} toggle={handleToggle} active={view} />
        {view === "enrollees" && (
          <TimeOffPolicyEnrolleesTable
            timeOffPolicy={timeOffPolicy}
            reloadEnrollees={refetchTeamMembers}
            onEmployeesChanged={saveEnrollees}
          />
        )}
        {view === "requests" && (
          <TimeOffRequestsTable
            defaultFilters={[{ field: "time_off_policy._id", value: timeOffPolicy._id, type: "_id" }]}
            showToggler={false}
          />
        )}
        {view === "updates" && <TimeOffUpdatesTable timeOffPolicy={timeOffPolicy} />}

        {view === "details" && (
          <div className="toggler-vertical-container">
            {timeOffPolicy.levels.length > 1 && (
              <div style={{ marginTop: 20 }}>
                <Toggler
                  config={togglerLevelConfig}
                  className={styles["level-toggler"]}
                  toggle={handleLevelToggle}
                  active={levelId}
                  type="vertical"
                />
              </div>
            )}
            <div className="toggler-right-content">
              {renderDetails(
                timeOffPolicy.levels.find((level) => {
                  return level._id === levelId;
                }),
                timeOffPolicy
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{timeOffPolicy?.name || "Time Off Policy"} | Miter</title>
      </Helmet>
      <div className="page-content">{renderContent()}</div>

      {showArchiveModal && (
        <ConfirmModal
          onYes={archiveTimeOffPolicy}
          onNo={() => setShowArchiveModal(false)}
          body={"Are you sure you want to delete this time off policy?"}
          loading={archiving}
        />
      )}
      {showEditingScreen && (
        <TimeOffPolicyForm
          timeOffPolicy={timeOffPolicy}
          onComplete={() => getTimeOffPolicy()}
          onHide={() => setShowEditingScreen(false)}
        />
      )}
      {renderEnrollmentFailuresModal()}
    </div>
  );
};

export default TimeOffPolicyPage;
