import React, { useMemo } from "react";
import { OnboardingItemsTable } from "./OnboardingItemsTable";
import { ColumnConfig } from "ui/table-v2/Table";
import { OnboardingItemTableRow } from "./OnboardingItemsTable";
import OnboardingCustomTaskModal from "./OnboardingCustomTaskModal";
import { OnboardingCustomTaskFormParams } from "./OnboardingCustomTaskModal";
import { CustomModalProps } from "./OnboardingCustomTaskModal";
import { TeamMemberGroup } from "backend/types";
import { Option } from "ui/form/Input";

export type OnboardingCustomTaskTableRow = Pick<
  OnboardingItemTableRow,
  "_id" | "default_team_member_checklists" | "default_due_days_from_start"
> & {
  title: string;
  description?: string;
  default_assignee?: TeamMemberGroup[] | null;
};

export type OnboardingCustomTasksTableProps = {
  tableId: string;
  objectOptions: Option<string>[];
  resourceName: string;
  handleRemove: (selectedRows: (OnboardingItemTableRow | OnboardingCustomTaskTableRow)[]) => Promise<void>;
  handleUpdate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  handleCreate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  tableData: OnboardingCustomTaskTableRow[];
  columns?: ColumnConfig<OnboardingCustomTaskTableRow>[];
  modalComponent?: React.ComponentType<CustomModalProps>;
};

export const OnboardingCustomTasksTable: React.FC<OnboardingCustomTasksTableProps> = (props) => {
  const columns: ColumnConfig<OnboardingCustomTaskTableRow>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        dataType: "string",
      },
      {
        field: "description",
        headerName: "Description",
        dataType: "string",
      },
    ],
    []
  );

  return (
    <OnboardingItemsTable
      {...props}
      columns={columns}
      modalComponent={OnboardingCustomTaskModal}
      includeDefaultAssignee={true}
      showCreateAction={true}
    />
  );
};

export default OnboardingCustomTasksTable;
