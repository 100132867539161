import React, { useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { MiterAPI } from "../../miter";
import { Notifier, Button, Toggler, Loader, ConfirmModal, Formblock } from "ui";
import {
  useActiveAccount,
  useActiveCompany,
  useActiveCompanyId,
  useStripeConnectedAccount,
} from "../../hooks/atom-hooks";
import { Params, useNavigate, useParams } from "react-router-dom";
import ExpenseReimbursements from "./ExpenseReimbursements";
import CardsLander from "./CardsLander";
import { initializeStripeAccount } from "dashboard/utils/expenses";
import { isMiterCardsAccountFullyActive } from "./expenseUtils";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import CardProgramTable from "./CardProgramTable";
import { CardTransactionsTable } from "./CardTransactionsTable";
import { useHasAccessToCardManagement } from "dashboard/gating";
import Vendors from "../vendors/Vendors";
import BillPay from "../bills/BillPay";
import { SLACK_CHANNEL } from "dashboard/utils";

const Expenses: React.FC = () => {
  /*********************************************************
   *  Use initial hooks
   **********************************************************/
  const { view, id } = useParams<Params>();
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const activeUser = useActiveAccount();
  const stripeAccount = useStripeConnectedAccount();
  const [hasThirdPartyCards, setHasThirdPartyCards] = useState<boolean>(true);
  const [isLoadingThirdPartyCards, setIsLoadingThirdPartyCards] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const { can, cannot } = useMiterAbilities();

  const alwaysHasAccessToCards = useHasAccessToCardManagement();

  const activePath = view;
  const isStripeAccountSetupComplete = isMiterCardsAccountFullyActive(stripeAccount);

  const showCardsOnboarding = useMemo(() => {
    // no third party cards or hasn't finished setting up Stripe account
    return !alwaysHasAccessToCards && !hasThirdPartyCards && !isStripeAccountSetupComplete;
  }, [hasThirdPartyCards, stripeAccount]);

  // modals
  const [stripeOnboardingModalOpen, setStripeOnboardingModalOpen] = useState<string>();
  const [stripeOnboardingUseCase, setStripeOnboardingUseCase] = useState<string>();

  /*********************************************************
   *  Configure toggler
   **********************************************************/
  const togglerConfig = useMemo(() => {
    if (showCardsOnboarding) {
      return [
        {
          path: "reimbursements",
          label: "Reimbursements",
          hide: cannot("reimbursements:others:read") && cannot("reimbursements:personal:read"),
        },
        {
          path: "bill-pay",
          label: "Bill pay",
          hide: cannot("bill_pay:bills:read"),
        },
        {
          path: "vendors",
          label: "Vendors",
          hide: cannot("bill_pay:bills:read"),
        },
        {
          path: "card-transactions",
          label: "Get started with cards",
          hide: cannot("miter_cards:create") && cannot("third_party_cards:create"),
        },
      ];
    } else {
      return [
        {
          path: "card-transactions",
          label: "Card transactions",
          hide: cannot("card_transactions:others:read") && cannot("card_transactions:personal:read"),
        },
        {
          path: "card-programs",
          label: "Card programs",
          hide: cannot("miter_cards:read") && cannot("third_party_cards:read"),
        },
        {
          path: "reimbursements",
          label: "Reimbursements",
          hide: cannot("reimbursements:others:read") && cannot("reimbursements:personal:read"),
        },
        {
          path: "bill-pay",
          label: "Bill pay",
          hide: cannot("bill_pay:bills:read"),
        },
        {
          path: "vendors",
          label: "Vendors",
          hide: cannot("bill_pay:bills:read"),
        },
      ];
    }
  }, [showCardsOnboarding, cannot]);

  const toggle = (page: string) => {
    return navigate("/expenses/" + page);
  };

  useEffect(() => {
    if (isLoadingThirdPartyCards) return;

    // get first toggler path not hidden
    const defaultPath = togglerConfig.find((item) => !item.hide)?.path;
    if (!activePath) {
      navigate(`/expenses/${defaultPath}`, { replace: true });
    }
  }, [isLoadingThirdPartyCards, togglerConfig, activePath]);

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderToggler = () => <Toggler config={togglerConfig} active={activePath} toggle={toggle} />;

  const renderView = () => {
    if (activePath === "card-transactions") {
      const canReadCardTransactions =
        can("card_transactions:others:read") || can("card_transactions:personal:read");

      if (!canReadCardTransactions) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (showCardsOnboarding) {
        return <CardsLander stripeAccount={stripeAccount} refreshData={getThirdPartyCards} />;
      } else {
        return <CardTransactionsTable shouldRedirectURLWhenOpening={true} />;
      }
    } else if (activePath === "card-programs") {
      const canReadCards = can("miter_cards:read") || can("third_party_cards:read");
      if (!canReadCards) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (id) {
        navigate(`/expenses/card-programs/${id}`);
      }
      return <CardProgramTable />;
    } else if (activePath === "reimbursements") {
      const canReadReimbursements = can("reimbursements:others:read") || can("reimbursements:personal:read");
      if (!canReadReimbursements) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }
      return <ExpenseReimbursements expenseReimbursementId={id} shouldRedirectURLWhenOpening={true} />;
    } else if (activePath === "bill-pay") {
      const canReadBillPay = can("bill_pay:bills:read");
      if (!canReadBillPay) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      return <BillPay billId={id} />;
    } else if (activePath === "vendors") {
      const canReadBillPay = can("bill_pay:bills:read");
      if (!canReadBillPay) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      return <Vendors vendorId={id} />;
    }
  };

  const getThirdPartyCards = async () => {
    if (!activeCompanyId) return;

    try {
      const filter = [{ field: "company_id", value: activeCompanyId }];
      const res = await MiterAPI.expenses.third_party_cards.list({ filter });
      if (res.error) throw new Error(res.error);

      setHasThirdPartyCards(res?.length > 0);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error(e.message);
    }
    setIsLoadingThirdPartyCards(false);
  };

  useEffect(() => {
    getThirdPartyCards();
  }, [activeCompanyId]);

  const renderStripeOnboadingButton = () => {
    if (isStripeAccountSetupComplete) return <></>;

    let text;
    if (activePath === "card-transactions" && can("miter_cards:create")) {
      text = "Miter spend cards";
    } else if (activePath === "card-programs") {
      text = "Miter spend cards";
    } else if (activePath === "reimbursements" && can("reimbursements:others:process_payment")) {
      text = "ACH reimbursements";
    } else if ((activePath === "bill-pay" || activePath === "vendors") && can("bill_pay:bills:pay")) {
      text = "ACH payments";
    }

    return (
      <Button
        className="button-2"
        text={`${!stripeAccount ? `Get started with ${text}` : `Continue ${text} onboarding`}`}
        onClick={() => (stripeAccount ? handleRedirectToStripe() : setStripeOnboardingModalOpen(text))}
        loading={loadingStripe}
      />
    );
  };

  const handleRedirectToStripe = async () => {
    setLoadingStripe(true);

    if (!stripeAccount) {
      // send to slack
      await MiterAPI.slack.send_message(
        `${activeUser?.email || activeUser?._id} from ${
          activeCompany?.check_company.trade_name
        } started Stripe onboarding for ${stripeOnboardingModalOpen}. Use case: ${stripeOnboardingUseCase}`,
        SLACK_CHANNEL.STRIPE_ONBOARDING
      );
    }

    await initializeStripeAccount({
      company: activeCompanyId!,
      account: stripeAccount,
      source: `Get started with ${stripeOnboardingModalOpen} button`,
      setLoading: setLoadingStripe,
    });

    // reset
    setStripeOnboardingUseCase(undefined);
    setStripeOnboardingModalOpen(undefined);
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Expense Management | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Expense Management</h1>
          <div className="flex-1"></div>
          {renderStripeOnboadingButton()}
          {can("expenses:settings") && (
            <Button
              className="button-1"
              text="Policies and settings"
              onClick={() => navigate("/expenses/settings/policies")}
            />
          )}
        </div>
        {isLoadingThirdPartyCards ? (
          <Loader />
        ) : (
          <>
            {renderToggler()}
            {renderView()}
          </>
        )}
        {stripeOnboardingModalOpen && (
          <ConfirmModal
            title="Confirmation"
            body={
              <>
                <Formblock
                  type="text"
                  editing={true}
                  label="What is your use case?"
                  className="modal"
                  onChange={(e) => setStripeOnboardingUseCase(e.target.value)}
                />
                <div className="yellow-text-container">
                  <p>
                    You will be redirected from Miter to pass required compliance steps before you can start
                    using {stripeOnboardingModalOpen}.
                    <br />
                    <br />
                    If you have any questions, please reach out to your dedicated Launch Manager or{" "}
                    <a href="mailto:justin@miter.com">justin@miter.com</a>.
                  </p>
                </div>
              </>
            }
            onYes={handleRedirectToStripe}
            onNo={() => setStripeOnboardingModalOpen(undefined)}
            yesText="Continue"
            yesDisabled={!stripeOnboardingUseCase}
            loading={loadingStripe}
          />
        )}
      </div>
    </div>
  );
};

export default Expenses;
